// import store
import store from "../store/index";

// POST api/v1/negativeaction/create
const negativeActionCreate = async (negativeAction: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/create`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify(negativeAction)
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/negativeaction/readall
const negativeActionReadAll = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/readall`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateNegativeActionCache", {
                negativeActionList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateNegativeActionCache", {
                negativeActionList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateNegativeActionCache", {
            negativeActionList: []
        });
        // return
        return [];
    }
};
// GET api/v1/negativeaction/read/:negativeActionId
const negativeActionRead = async (negativeActionId: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/read/${negativeActionId}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return null;
        }
    }
    catch (error) {
        // return
        return null;
    }
};
// DELETE api/v1/negativeaction/delete/:negativeActionId
const negativeActionDelete = async (negativeActionId: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/delete/${negativeActionId}`, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/negativeaction/verify
const negativeActionVerify = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/validate`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/negativeaction/handle
const negativeActionHandle = async () => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/handle`, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// GET api/v1/negativeaction/filtered/:negativeActionStatus
const negativeActionFiltered = async (negativeActionStatus: any) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/filtered/${negativeActionStatus}`, {
            method: "GET",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
            },
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // MANIPULATE STORE
            store.commit("updateNegativeActionCache", {
                negativeActionList: jsonResponse.data
            });
            // return
            return jsonResponse.data;
        }
        else {
            // MANIPULATE STORE
            store.commit("updateNegativeActionCache", {
                negativeActionList: []
            });
            // return
            return [];
        }
    }
    catch (error) {
        // MANIPULATE STORE
        store.commit("updateNegativeActionCache", {
            negativeActionList: []
        });
        // return
        return [];
    }
};
// POST api/v1/negativeaction/getforcampaign
const negativeActionGetForCampaign = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/getforcampaign`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            return jsonResponse.data;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
};
// POST api/v1/negativeaction/getforcampaign
const negativeActionGetForAdGroup = async (adGroupId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/getforadgroup`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            return jsonResponse.data;
        }
        else {
            return false;
        }
    }
    catch (error) {
        return false;
    }
};
// POST api/v1/negativeaction/validateforcampaign
const negativeActionValidateForCampaign = async (campaignId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/validateforcampaign`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/negativeaction/validateforadgroup
const negativeActionValidateForAdGroup = async (adGroupId: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/validateforadgroup`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return jsonResponse.data;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/negativeaction/applytocampaign
const negativeActionApplyToCampaign = async (campaignId: string, hash: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/applytocampaign`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                campaignId: campaignId,
                hash: hash
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            if (jsonResponse.data === false) {
                // return
                return false;
            }
            else {
                // return
                return true;
            }
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// POST api/v1/negativeaction/applytoadgroup
const negativeActionApplyToAdGroup = async (adGroupId: string, hash: string) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/applytoadgroup`, {
            method: "POST",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                adGroupId: adGroupId,
                hash: hash
            })
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            if (jsonResponse.data === false) {
                // return
                return false;
            }
            else {
                // return
                return true;
            }
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};
// DELETE api/v1/negativeaction/remove/:negativeActionId
const negativeActionRemove = async (negativeActionId: number) => {
    try {
        // MAKE REQUEST
        const response = await fetch(`${store.state.serverAddress}api/v1/negativeaction/remove/${negativeActionId}`, {
            method: "DELETE",
            mode: "cors",
            headers: {
                Authorization: `Bearer ${store.state.auth.key}`,
                "Content-Type": "application/json"
            }
        });
        // PARSE RESPONSE BODY
        const jsonResponse = await response.json();
        // HANDLE RESULT
        if (jsonResponse.result == "success") {
            // return
            return true;
        }
        else {
            // return
            return false;
        }
    }
    catch (error) {
        // return
        return false;
    }
};

// export
export {
    negativeActionCreate,
    negativeActionReadAll,
    negativeActionRead,
    negativeActionDelete,
    negativeActionVerify,
    negativeActionHandle,
    negativeActionFiltered,
    negativeActionGetForCampaign,
    negativeActionValidateForCampaign,
    negativeActionApplyToCampaign,
    negativeActionGetForAdGroup,
    negativeActionValidateForAdGroup,
    negativeActionApplyToAdGroup,
    negativeActionRemove
};